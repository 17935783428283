/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    sliders: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" fill-rule="evenodd" d="M11.5 2a1.5 1.5 0 100 3 1.5 1.5 0 000-3M9.05 3a2.5 2.5 0 014.9 0H16v1h-2.05a2.5 2.5 0 01-4.9 0H0V3zM4.5 7a1.5 1.5 0 100 3 1.5 1.5 0 000-3M2.05 8a2.5 2.5 0 014.9 0H16v1H6.95a2.5 2.5 0 01-4.9 0H0V8zm9.45 4a1.5 1.5 0 100 3 1.5 1.5 0 000-3m-2.45 1a2.5 2.5 0 014.9 0H16v1h-2.05a2.5 2.5 0 01-4.9 0H0v-1z"/>',
    },
});
